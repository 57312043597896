import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

// compositions
import CareerDetails from '@compositions/CareerDetails'

const Career = ({ data }) => {
  const career = data.wpgraphql.career
  return <CareerDetails data={career} />
}

Career.propTypes = {
  data: object.isRequired
}

Career.defaultProps = {
  data: {}
}

export default Career

export const careerQuery = graphql`
  query GET_CAREER($id: ID!) {
    wpgraphql {
      career(id: $id) {
        id
        title
        uri
        careerPageFields {
          careerFormId
          careerSeo {
            description
            title
          }
          careerHero {
            description
            headerText
          }
          careerPageElement {
            __typename
            ... on WPGraphQL_Career_Careerpagefields_CareerPageElement_Textblockelementdata {
              content
            }
            ... on WPGraphQL_Career_Careerpagefields_CareerPageElement_Contentelementdata {
              headline
              border
              list {
                text
              }
            }
          }
        }
        careerCategories {
          nodes {
            name
          }
        }
      }
    }
  }
`
