import React, { useState, useEffect } from 'react'
import { string } from 'prop-types'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import axios from 'axios'
import { getWpURl } from '../../../../helpers'

// utils
import colors from '@styles/js/colors'
import { Link } from 'gatsby'

var FormData = require('form-data')

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] = 'application/json'

const schema = Yup.object({
  FirstName: Yup.string().required('This is required'),
  LastName: Yup.string().required('This is required'),
  email: Yup.string().required('This is required'),
  phoneNumber: Yup.string().required('This is required'),
  resume: Yup.string()
    .required('This is required')
    .test('fileSize', 'File is too large', value => {
      //TODO: Check this again later for React Solution Yup
      if (value === undefined) return false
      var fileSize = document.getElementById('file').files[0].size
      return fileSize <= 2000000
    }),
  linkedinProfile: Yup.string().required('This is required'),
  website: Yup.string().required('This is required'),
  aboutjob: Yup.string().required('This is required'),
  policy: Yup.string().required('This is required')
})

const SUCCESS = 'success'
const INFO = 'info'
const ERROR = 'danger'
let tmr = null

//const Apply = styled(({ className }) => (
const Apply = styled(({ formID, className }) => {
  const isBrowser = typeof window !== 'undefined'
  const hostUri = isBrowser ? window.location.host : null
  const [message, setMessage] = useState({
    type: SUCCESS,
    text: '',
    show: false
  })

  useEffect(() => {
    return () => hideMessage(tmr)
  }, [])

  function hideMessage() {
    clearTimeout(tmr)
    setMessage({
      show: false
    })
  }
  function showMessage(text, type = INFO) {
    setMessage({
      type,
      text,
      show: true
    })
    tmr = setTimeout(() => {
      hideMessage()
    }, 10000)
  }

  const [checkbox, setCheckbox] = useState(false)

  const handleSubmit = (model, { setSubmitting, resetForm }) => {
    showMessage('Sending message...')

    const {
      FirstName,
      LastName,
      email,
      phoneNumber,
      linkedinProfile,
      website,
      aboutjob,
      policy
    } = model

    const postUrl =
      getWpURl(hostUri) +
      '/codistry-api/contact-form-7/v1/contact-forms/' +
      formID +
      '/feedback'

    var data = new FormData()

    data.append('your-first-name', FirstName)
    data.append('your-last-name', LastName)
    data.append('your-email', email)
    data.append('your-phone', phoneNumber)
    data.append('your-linkedin', linkedinProfile)
    data.append('your-website', website)
    data.append('your-aboutjob', aboutjob)

    var policyVal = ''
    if (policy === true) {
      policyVal = 'Accepted'
    } else {
      policyVal = 'Not Accepted'
    }

    data.append('your-policy', policyVal)

    var uploadField = document.getElementById('file') //TODO: Check this again later for React Solution Yup
    data.append('your-resume', uploadField.files[0])

    var config = {
      data: data,
      method: 'post',
      url: postUrl,
      mode: 'no-cors',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      credentials: 'same-origin'
    }

    axios(config)
      .then(res => {
        if (res.status === 200) {
          resetForm()
          showMessage('Message successfully sent!')
        }
      })
      .catch(err => {
        setSubmitting(false)
        if (err) {
          showMessage(
            'Sorry, but something went wrong. Please try again',
            ERROR
          )
          return console.error(err)
        }
      })
  }

  return (
    <Formik
      validationSchema={schema}
      // eslint-disable-next-line no-console
      onSubmit={handleSubmit}
      initialValues={{
        FirstName: '',
        LastName: '',
        email: '',
        phoneNumber: '',
        resume: '',
        linkedinProfile: '',
        website: '',
        aboutjob: '',
        policy: ''
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        errors
      }) => (
        <Form className={className} noValidate onSubmit={handleSubmit}>
          <div id="form-area"></div>
          <h3 className="text-center title">Apply Now</h3>
          <div className="row">
            <Form.Group className="col-12 col-md-6 short-input-right">
              <Form.Label className="label-text">First Name</Form.Label>
              <Form.Control
                type="text"
                name="FirstName"
                value={values.FirstName}
                onChange={handleChange}
                isInvalid={!!errors.FirstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.FirstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-12 col-md-6 short-input-left">
              <Form.Label className="label-text">Last Name</Form.Label>
              <Form.Control
                type="text"
                name="LastName"
                value={values.LastName}
                onChange={handleChange}
                isInvalid={!!errors.LastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.LastName}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Label className="label-text">Your Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className="label-text">Phone Number</Form.Label>
            <Form.Control
              type="text"
              name="phoneNumber"
              value={values.phoneNumber}
              onChange={handleChange}
              isInvalid={!!errors.phoneNumber}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phoneNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className="label-text resume" htmlFor="file">
              RESUME / CV
            </Form.Label>
            <Form.Control
              accept="application/msword, application/pdf"
              type="file"
              placeholder="Upload Your resume"
              name="resume"
              id="file"
              value={values.resume}
              onChange={handleChange}
              isInvalid={!!errors.resume}
            />
            <Form.Control.Feedback type="invalid">
              {errors.resume}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className="label-text">Linkedin Profile</Form.Label>
            <Form.Control
              type="text"
              name="linkedinProfile"
              value={values.linkedinProfile}
              onChange={handleChange}
              isInvalid={!!errors.linkedinProfile}
            />
            <Form.Control.Feedback type="invalid">
              {errors.linkedinProfile}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className="label-text">Website</Form.Label>
            <Form.Control
              type="text"
              name="website"
              value={values.website}
              onChange={handleChange}
              isInvalid={!!errors.website}
            />
            <Form.Control.Feedback type="invalid">
              {errors.website}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className="label-text">
              How did you hear about this job?
            </Form.Label>
            <Form.Control
              as="textarea"
              name="aboutjob"
              placeholder="Comment"
              value={values.aboutjob}
              onChange={handleChange}
              isInvalid={!!errors.aboutjob}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.aboutjob}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            controlId="formCheckbox"
            className="mb-0 d-flex align-items-start formCheckbox"
          >
            <Form.Check
              type="checkbox"
              name="policy"
              value={values.policy}
              isInvalid={!!errors.policy}
              onChange={() => {
                handleChange()
                setCheckbox(!checkbox)
              }}
            />
            <span
              className={`checkmark ${checkbox ? 'active' : 'deactive'}`}
            ></span>
            <Form.Label className="form-check-label">
              I acknowledge that I have read and understood the{' '}
              <Link to="/privacy-policy/">Privacy Policy</Link>
            </Form.Label>
            <Form.Control.Feedback type="invalid">
              {errors.policy}
            </Form.Control.Feedback>
          </Form.Group>
          {/* <Button className="apply" type="submit">Send Application</Button> */}
          <Form.Group className="mb-0">
            {/* <StyledButton disabled={!isValid || isSubmitting} type="submit"> */}
            <Button type="submit" className="apply">
              {isSubmitting ? 'Sending...' : 'Send Application'}
            </Button>
          </Form.Group>
          {message.show && (
            <Form.Group>
              <Alert variant={message.type}>{message.text}</Alert>
            </Form.Group>
          )}
        </Form>
      )}
    </Formik>
  )
})`
  background-color: ${colors.white};
  padding: 36px 17px;
  position: relative;
  margin: 0 15px;

  #form-area {
    position: absolute;
    top: -50px;
  }

  @media (min-width: 768px) {
    padding: 48px 16px 24px;
  }

  @media (min-width: 992px) {
    padding: 36px 40px 16px 41px;
    margin: -370px 0 0 auto;
    width: 381px;
  }

  @media (min-width: 1440px) {
    padding: 36px 20px 24px;
    margin: -390px 0 0 auto;
    width: 435px;
  }

  .title {
    color: ${colors.black};
    font-family: Roboto;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    margin-bottom: 34px;

    @media (min-width: 992px) {
      font-size: 36px;
      margin-bottom: 32px;
    }
  }

  .short-input {
    width: calc(100% - 7px);
  }

  .form-control {
    border: 1px solid #ced4da;
    color: #8e8e8e;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.8px;
    height: 40px;
    padding: 16px;
  }

  textarea {
    height: 117px !important;
    resize: none;
  }

  .formCheckbox {
    position: relative;

    .checkmark {
      border: 1px solid #c5c5c5;
      cursor: pointer;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:hover {
        background-color: #c5c5c5;
      }
      &.active {
        border-color: #2196f3;
        background-color: #2196f3;
        &:after {
          display: block;
        }
      }
    }
  }

  .label-text {
    color: ${colors.black};
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 1px;
    margin-bottom: 8px;
    margin-left: 10px;

    &.short-input-label {
      margin-left: 17px;
    }
  }

  @media (min-width: 768px) {
    .form-group {
      &.short-input-right {
        padding-right: 7.5px !important;
      }

      &.short-input-left {
        padding-left: 7.5px !important;
      }
    }
  }

  .form-check {
    display: inline-flex;
    padding: 0;
    width: 38px;

    .form-check-input {
      margin: 0;
      height: 20px;
      opacity: 0;
      width: 20px;
      z-index: 1;
    }
  }

  .form-check-label {
    color: #8e8e8e;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.8px;
    margin-bottom: 32px;
  }

  .resume {
    background-color: #e3f3e5;
    color: #000000;
    display: block;
    text-align: center;
    padding: 15px 0;
    margin: 0;
  }

  #file {
    display: none;
  }

  .apply {
    background-color: ${colors.saffron};
    color: ${colors.black};
    letter-spacing: 0.03em;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 992px) {
      display: block;
    }

    &:hover {
      background-color: ${colors.gamboge};
    }
  }
`

Apply.propTypes = {
  formID: string.isRequired
}

Apply.defaultProps = {
  formID: {}
}

export default Apply
