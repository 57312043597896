import React from 'react'
import { object } from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from '@emotion/styled'

// components
import Layout from '@compositions/Layout'
import SEO from '@components/SEO'
import Hero from './components/Hero'
import TitleWithList from './components/TitleWithList'
import Apply from './components/Apply'

import Facebook from '@images/careers/facebook.svg'
import Twitter from '@images/careers/twitter.svg'
import Mail from '@images/careers/mail.svg'

const CareerDetails = styled(({ data, className }) => {
  const { careerPageFields, careerCategories } = data || ''

  const { careerFormId, careerSeo, careerHero, careerPageElement } =
    careerPageFields || ''

  // SEO data
  const { title, description } = careerSeo

  const heroData = { careerHero, careerCategories }

  const url = typeof window !== 'undefined' ? window.location : ''
  return (
    <Layout className={`${className}`}>
      <SEO
        title={title}
        description={description}
        image="hero/careers-hero.jpg"
      />
      <div className="bg-alabaster">
        {/* Hero */}
        <section className="d-inline-block w-100">
          <Hero data={heroData} />
        </section>
        {/* Job Details */}
        <section className="mb-xl-3 d-inline-block w-100 career-detail-inner">
          <div className="d-flex align-items-center position-relative">
            <Container>
              <Row>
                <Col lg={7} className="content-area pl-lg-0">
                  <div className="social-left">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.facebook.com/sharer/sharer.php?${url}`}
                    >
                      <Facebook />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://twitter.com/intent/tweet?url=${url} ${encodeURIComponent(
                        description
                      )}`}
                    >
                      <Twitter />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:?subject=${title}&body=${encodeURIComponent(
                        description
                      )}%0D%0D${url}`}
                    >
                      <Mail />
                    </a>
                  </div>
                  <div className="content-area-inner">
                    {careerPageElement.map((section, key) => {
                      const { __typename: typename } = section || {}
                      switch (typename) {
                        case 'WPGraphQL_Career_Careerpagefields_CareerPageElement_Textblockelementdata':
                          return (
                            <p className="text-black" key={key}>
                              {section.content}
                            </p>
                          )
                        case 'WPGraphQL_Career_Careerpagefields_CareerPageElement_Contentelementdata':
                          return <TitleWithList data={section} key={key} />
                        default:
                          return false
                      }
                    })}
                  </div>
                </Col>
                <Col lg={5} className="p-0 pl-lg-0 pl-xl-3">
                  <Apply formID={careerFormId} />
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
    </Layout>
  )
})`
  .career-detail-inner {
    position: relative;
    padding: 56px 8px 29px 7px;

    @media (min-width: 768px) {
      padding: 110px 0 80px 0;

      > div {
        padding: 0 31px;
      }
    }

    .content-area {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 1024px) {
        padding-right: 43px;
      }

      .content-area-inner {
        width: 100%;

        @media (min-width: 1024px) {
          width: calc(100% - 61px);
        }

        @media (min-width: 1200px) {
          width: calc(100% - 71px);
        }
      }

      .social-left {
        display: none;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 0;
        width: 24px;
        margin-right: 37px;

        @media (min-width: 1024px) {
          display: flex;
        }

        @media (min-width: 1200px) {
          margin-right: 47px;
        }

        svg {
          color: #8e8e8e;
          display: block;
          //opacity: 0.4;
          margin: 0 auto 20px;

          @media (min-width: 1024px) {
            &.facebook {
              width: 11px;
              height: 20px;
            }
            &.twitter {
              width: 19px;
              height: 16px;
            }
            &.envelope {
              width: 24px;
              height: 18px;
            }
          }
        }
      }
    }

    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 1px;
      margin-bottom: 48px;

      @media (min-width: 768px) {
        margin-bottom: 64px;
      }
    }
  }
`

CareerDetails.propTypes = {
  data: object.isRequired
}

CareerDetails.defaultProps = {
  data: {}
}

export default CareerDetails
