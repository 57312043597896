import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'

// utils
import colors from '@styles/js/colors'

const TitleWithList = styled(
  ({ data: { headline, border, list }, className }) => (
    <div className={className}>
      <h3 className="title">{headline}</h3>
      <ul
        className={
          `p-0 ` + (border === null || border === false ? '' : 'border-list')
        }
      >
        {list.map((item, index) => (
          <li className="item" key={index}>
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  )
)`
  &:last-child{
    ul{
      @media (min-width: 992px) {
        margin-bottom: 0;
      }
    }
  }

  .title {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 37px;
    letter-spacing: normal;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      margin-bottom: 32px;
    }

    &:after {
      background-color: ${colors.fern};
      content: '';
      display: block;
      height: 2px;
      margin-top: 24px;
      width: 100px;

      @media (min-width: 768px) {
        display: inline-block;
        margin-left: 16px;
        margin-top: 0;
        vertical-align: middle;
        width: 65px;
      }
    }
  }

  ul{
    margin: 0 0 48px;

    @media (min-width: 768px) {
      margin-bottom: 80px;
    }

    &:not(.border-list){
      .item{
        padding-bottom: 32px;
      }
    }

    &.border-list{

      .item{
        border-bottom: 1px solid #e5e5e5;
        padding: 32px 0 16px;
      }

      @media (min-width: 568px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item{
          flex: 0 0 47%;
          padding: 32px 0 16px;
          max-width: 47%;
        }
      }
    }
  }

  .item {
    color: #8e8e8e;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    list-style: none;

    &:last-child{
      border: none !important;
      padding-bottom: 0 !important;
    }

    &.first-of-type
      padding-top: 0 !important;
    }
  }
`

TitleWithList.propTypes = {
  data: object.isRequired
}

TitleWithList.defaultProps = {
  data: {}
}

export default TitleWithList
