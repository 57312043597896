/* eslint-disable no-useless-escape */
export const getCategoryName = arr => {
  const { nodes: categoryNodes } = arr || ''
  const categories = categoryNodes.length > 0 ? categoryNodes : []

  return categories.map(cat => {
    return cat.name
  })
}
export const getWpURl = currentUrl => {
  let url = []
  if (currentUrl === 'develop.gocodistry.com') {
    url = 'https://admin-dev.gocodistry.com'
  } else if (currentUrl === 'staging.gocodistry.com') {
    url = 'https://admin-staging.gocodistry.com'
  } else if (
    currentUrl === 'www.gocodistry.com' ||
    currentUrl === 'gocodistry.com'
  ) {
    url = 'https://admin.gocodistry.com'
  } else {
    url = 'https://admin.gocodistry.local'
  }
  return url
}

export const clearForm = myFormElement => {
  var elements = myFormElement.elements

  myFormElement.reset()

  for (let i = 0; i < elements.length; i++) {
    let field_type = elements[i].type.toLowerCase()

    switch (field_type) {
      case 'text':
      case 'email':
      case 'tel':
      case 'password':
      case 'textarea':
      case 'hidden':
        elements[i].value = ''
        break

      case 'radio':
      case 'checkbox':
        if (elements[i].checked) {
          elements[i].checked = false
        }
        break

      case 'select-one':
      case 'select-multi':
        elements[i].selectedIndex = -1
        break

      default:
        break
    }
  }
}

export const slugify = (string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "")
}