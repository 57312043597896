import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'gatsby'
import Button from 'react-bootstrap/Button'

// components
import Hr from '@components/Hr'

// assets
import BackIcon from '@images/back.svg'

// utils
import colors from '@styles/js/colors'

const scroll = () => {
  const section = document.querySelector('#form-area')
  section.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

const Hero = styled(({ data: { careerHero, careerCategories }, className }) => (
  <div
    className={`${className} bg-cerulean-blue d-flex align-items-center position-relative`}
  >
    <Container>
      <Row>
        <Col xs={12} lg={7}>
          {/* <Wrapper data={data} /> */}

          <div className="wrapper">
            <Link className="d-flex align-items-center back" to="/careers/">
              <BackIcon />
              <span>Back</span>
            </Link>
            <p className="text-white d-inline-block position-relative category">
              {careerCategories.nodes.map((cat, key) => {
                return <span key={key}>{cat.name}</span>
              })}
            </p>
            <h1 className="text-white">{careerHero.headerText}</h1>
            <Hr />
            <p className="text-white content">{careerHero.description}</p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
))`
  position: relative;
  padding: 50px 8px 128px 7px;

  @media (min-width: 992px) {
    padding: 20px 25px 79px;
  }

  @media (min-width: 1440px) {
    padding: 51px 0 138px;
  }

  &:before {
    background: #f5c141;
    bottom: -24px;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    content: '';
    height: 128px;
    left: 0;
    position: absolute;
    width: 148px;
    z-index: 1;

    @media (min-width: 768px) {
      bottom: -110px;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      height: 190px;
      width: 249px;
    }

    @media (min-width: 1440px) {
      bottom: -179px;
      height: 259px;
      width: 340px;
    }
  }

  .wrapper {
    .back {
      color: ${colors.white};
      margin-bottom: 24px;

      span {
        color: #ffffff;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 1px;
        margin-left: 9px;
      }

      &:hover {
        color: ${colors.white};
        text-decoration: none;
      }

      .back {
        color: ${colors.saffron};
      }
    }

    .category {
      color: #ffffff;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 1px;
      margin-bottom: 24px;

      &:after {
        background-color: #f5c141;
        content: '';
        height: 1px;
        position: absolute;
        right: -3rem;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
      }
    }

    h1 {
      color: #ffffff;
      font-family: Roboto;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 47px;
      letter-spacing: normal;
      margin-bottom: 24px;

      @media (min-width: 768px) {
        font-size: 64px;
        line-height: 75px;
        margin-bottom: 16px;
      }
    }

    hr {
      height: 1px;
      margin-bottom: 24px;
    }

    .content {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 1px;
      margin-bottom: 0;
    }

    .apply {
      background-color: ${colors.saffron};
      color: ${colors.black};
      font-family: Roboto;
      font-size: 20px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      height: auto !important;
      text-align: center;
      padding: 16px 0 !important;
      width: 100%;

      &:hover {
        background-color: ${colors.gamboge};
      }

      @media (min-width: 768px) {
        display: none;
        width: auto;
      }
    }
  }
`

Hero.propTypes = {
  data: object.isRequired
}

Hero.defaultProps = {
  data: {}
}

export default Hero
